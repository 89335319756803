<template>
  <div class="container">
    <header-bar>
      <template #search>
        <div class="wxq_left_search">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            v-model="input2"
          ></el-input>
          <el-button class="btn btn_blue">搜索</el-button>
        </div>
      </template>
    </header-bar>
    <headers
      :data="formData"
      :num="courseNum"
      @showCheck="showCheck"
      @showLoads="showLoads"
      @getFilter="search"
    ></headers>
    <div class="hh_del_wrap" v-if="isDel">
      <p>
        <el-checkbox v-model="checkedAll" @change="checkAll">全选</el-checkbox
        ><span>已选中{{ checkNum }}个作品</span>
      </p>
      <p>
        <el-checkbox v-model="checkedAnti" @change="checkAnti"
          >反选</el-checkbox
        >
      </p>
      <p @click="delAll()"><i class="el-icon-delete"></i>删除</p>
    </div>
    <div class="hh_video_wrap" v-if="fileList.length > 0">
      <div class="hh_video" v-for="(item, index) in fileList" :key="index">
        <el-checkbox
          v-if="isChecked"
          :value="item.isCheck"
          @change="checkFull(item)"
        ></el-checkbox>
        <!--基础设置-->
        <div class="hh_basic_setting">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link"
              >基础设置<i class="el-icon-more el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handClick(item)">
                <img
                  src="@/assets/img/backStageImg/myCourse/share-icon.png"
                  alt=""
                />复制链接
              </el-dropdown-item>
              <el-dropdown-item @click.native="downLoad(item)">
                <img
                  src="@/assets/img/backStageImg/myCourse/downLoad-icon.png"
                  alt=""
                />下载
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="del(item.fileId)"
                style="color: #f65160"
              >
                <img
                  src="@/assets/img/backStageImg/myCourse/delete-icon.png"
                  alt=""
                />删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="pageImg">
          <img
            v-if="item.fileExt == 'pdf'"
            src="@/assets/img/backStageImg/myCourse/pdfLogo.png"
            alt=""
          />
          <img
            v-if="item.fileExt == 'pptx' || item.fileExt == 'ppt'"
            src="@/assets/img/backStageImg/myCourse/pptLogo.png"
            alt=""
          />
          <img
            v-if="
              item.fileExt == 'doc' ||
              item.fileExt == 'word' ||
              item.fileExt == 'msword' ||
              item.fileExt == 'docx'
            "
            src="@/assets/img/backStageImg/myCourse/docLogo.png"
            alt=""
          />
        </div>
        <p class="hh_detail_title" v-show="!item.isEditable">
          {{ item.fileName }}
        </p>
        <el-input
          class="hh_detail_title"
          v-show="item.isEditable"
          v-model="item.fileName"
          maxlength="50"
          show-word-limit
        ></el-input>
        <div class="hh_txt_wrap">
          <div class="hh_txt">
            <p>上传时间 {{ item.createTime }}</p>
            <p>文件大小 {{ item.fileSize }}M</p>
          </div>
          <el-button
            class="hh_btn bg_blue"
            type="primary"
            :icon="
              item.isEditable ? 'el-icon-finished' : 'el-icon-edit-outline'
            "
            @click="reName(item)"
            >{{ item.isEditable ? "保存" : "重命名" }}</el-button
          >
        </div>
      </div>
    </div>
    <none-data v-else @showLoads="showLoads"></none-data>
    <pagination
      v-if="totalCount > 12"
      :pageSize="formData.pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      ref="child"
    />
    <el-dialog
      class="dialog_wrapper"
      :title="title"
      v-if="isAllUpLoads"
      :visible.sync="isAllUpLoads"
      :close-on-click-modal="false"
      width="30%"
    >
      <all-up-load
        :action="action"
        :upStyle="'filesList'"
        @showLoads="showLoads"
        :actionType="actionType"
      ></all-up-load>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@comps/backStageComponent/pagination";
import noneData from "@comps/backStageComponent/noneData";
import headers from "./components/headers";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import allUpLoad from "./components/allUpLoad";
import CommonUtils from "@utils/index.js";
import API from "@apis/backStageEndAPI/coursewareManagementAPI";
import useClipboard from "vue-clipboard3";
export default {
  name: "documentLibrary",
  components: {
    headers,
    headerBar,
    allUpLoad,
    noneData,
    Pagination,
  },
  data() {
    return {
      totalCount: 0,
      courseNum: 0,
      checkNum: 0,
      input2: "",
      fileIds: [],
      action: "文档支持：DOC、PPT、PDF 等格式",
      actionType: 1,
      title: "上传文档",
      isChecked: false,
      isAllUpLoads: false,
      checkedAll: false,
      checkedAnti: false,
      isDel: false,
      formData: {
        page: 1,
        pageSize: 12,
        fileGroup: 1,
        keyword: "",
      },
      options: [
        {
          value: "0",
          label: "上传时间",
        },
      ],
      fileList: [],
      fileData: {
        duration: 0, //时长
        fileExt: "doc", //扩展名
        fileGroup: 1, //文件分类
        fileId: "",
        fileName: "", //fileName文件名
        filePath: "http://image.vmh.com/course/org/1/user/1/course-file.doc", //路径
        fileSize: 20.12, //文件大小
        videoId: "",
      },
    };
  },
  mounted() {
    this.getFileList();
  },
  methods: {
    pageChange(val) {
      //页面跳转
      this.formData.page = val;
      this.getFileList();
    },
    // 批量删除
    delAll() {
      let _this = this;
      let str = [];
      let selectList = [];
      str = this.fileList.filter((f) => f.isCheck == true);
      str.forEach((ele) => {
        selectList.push(ele.fileId);
        this.fileIds = selectList.join(",");
      });
      this.$confirm("确认要删除此文件吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.delFileList(this.fileIds).then((res) => {
          _this.getFileList();
          _this.checkedAll = false;
          _this.checkedAnti = false;
          _this.checkNum = 0;
        });
      });
    },
    // 单选
    checkFull(item) {
      this.checkedAll = false;
      this.checkedAnti = false;
      item.isCheck = !item.isCheck;
      let str = [];
      let selectList = [];
      str = this.fileList.filter((f) => f.isCheck == true);
      if (str.length > 0) {
        str.forEach((ele) => {
          selectList.push(ele.fileId);
          this.checkNum = selectList.length;
        });
      } else {
        this.checkNum = 0;
      }
    },
    // 全选
    checkAll() {
      if (this.checkedAll == true) {
        this.checkedAnti = false;
        this.checkNum = this.fileList.length;
        this.fileList.forEach((item) => {
          item.isCheck = true;
        });
      } else {
        this.checkNum = 0;
        this.fileList.forEach((item) => {
          item.isCheck = false;
        });
      }
    },
    // 反选
    checkAnti() {
      this.checkedAll = false;
      let selectList = [];
      this.fileList.forEach((item) => {
        item.isCheck = !item.isCheck;
      });
      selectList = this.fileList.filter((ele) => {
        return ele.isCheck;
      });
      this.checkNum = selectList.length;
    },
    //获取列表
    async getFileList() {
      let data = await API.getFileList(
        CommonUtils.parseToParams(this.formData)
      );
      this.fileList = data.dataList;
      this.courseNum = data.rowCount;
      this.totalCount = data.rowCount;
      this.fileList.length &&
        this.fileList.map((item) => {
          this.$set(item, "isEditable", false);
          this.$set(item, "isCheck", false);
        });
    },
    // 重命名
    reName(item) {
      if (item.isEditable) {
        //保存数据
        item.isEditable = false;
        this.fileData.fileExt = item.fileExt;
        this.fileData.fileId = item.fileId;
        this.fileData.fileName = item.fileName;
        this.fileData.filePath = item.filePath;
        this.fileData.fileSize = item.fileSize;
        API.updateFile(this.fileData).then((res) => {
          this.getFileList();
        });
      } else {
        item.isEditable = true;
      }
    },
    showLoads(bool) {
      this.isAllUpLoads = bool;
      this.getFileList();
    },
    // 删除
    del(id) {
      let _this = this;
      this.$confirm("确认要删除此文件吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        API.delFile(id).then((res) => {
          _this.getFileList();
        });
      });
    },
    // 点击批量处理显示的内容
    showCheck(bool) {
      this.isChecked = bool;
      this.isDel = bool;
      if (this.isDel) {
        this.checkedAll = false;
        this.checkedAnti = false;
        this.checkNum = 0;
        this.fileList.forEach((item) => {
          item.isCheck = false;
        });
      }
    },
    //查询
    search(val) {
      this.formData = val;
      this.formData.page = 1;
      if (this.totalCount > 12) {
        this.$refs.child.handleSize(1);
        this.getFileList();
      } else {
        this.getFileList(); //获取列表
      }
    },
    async copyPath(val) {
      //复制
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(val);
        this.$message.success("复制链接成功");
      } catch (e) {
        console.error(e);
      }
    },
    handClick(val) {
      let msg = val.filePath;
      this.copyPath(msg);
    },
    downLoad(file) {
      var name = file.fileName;
      var url = file.filePath;
      var suffix = url.substring(url.lastIndexOf("."), url.length);
      //跳过浏览直接下载
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement("a");
          const objectUrl = window.URL.createObjectURL(blob);
          a.download = name;
          a.href = objectUrl;
          a.click();
          window.revokeObjectURL(objectUrl);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/css/backStageEndCSS/myCourseware";
.pageImg {
  margin-bottom: 4px;
  background: #e8f1ff;
  text-align: center;
  line-height: 285px;
  img {
    width: 77px;
    height: 86px;
  }
}
</style>
